<template>
    <div>
        <v-app>
            <!-- Unauthorized Popup Start -->
            <v-dialog v-model="isUnauthorizedFlag" persistent max-width="500">
                <v-card>
                    <v-card-title>
                            <p class="ma-0 body-heading font-weight-semi-bold">{{ $t("Customer.CartPage.Error")}}</p>
                    </v-card-title>

                    <v-divider></v-divider>

                    <div class="pa-4">
                        <!-- Unauthorized Message -->
                        <p class="ma-0 body-text font-weight-regular" align="left">{{ $t("Customer.CartPage.Uh_oh_nothing_in_the_cart_Please_login_to_add_products_to_your_cart")}}</p>
                    </div>

                    <v-card-actions class="ma-0 pa-0">
                        <div class="px-4 pb-4 full-width-button d-flex justify-center align-center">
                            <button class="mt-2 px-4 py-2 rounded light-blue-background edit-address-action-button" @click="unauthorizedPopupClose()"><span class="px-2 py-2 body-text white-color-text font-weight-semi-bold">{{ $t("Customer.CartPage.Go_Home")}}</span></button>
                        </div>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <!-- Unauthorized Popup End -->

            <!-- Edit Address Popup Start -->
            <v-dialog v-model="editAddressPopup" persistent max-width="500" >
                <v-card class="adjust_pop">
                    <v-card-title class="adjust_header">
                        <div class="d-flex justify-space-between align-center full-width-button">
                            <p class="ma-0 body-heading font-weight-semi-bold">Edit Address</p>

                            <v-btn icon v-on:click.stop="editAddressPopupClose()" :disabled="editAddressClickedFlag"><img src="https://s3iconimages.mymedicine.com.mm/cancelButton.svg" alt="close-icon"></v-btn>
                        </div>
                    </v-card-title>

                    <v-divider></v-divider>

                    <div v-if="editAddressPopupLoader" class="f-flex align-center align-center">
                        <v-progress-circular size="20" indeterminate color="primary"></v-progress-circular>
                    </div>
                    <div v-else class="custom-dialog-content">
                        <div class="pa-4">
                            <!-- Deliver To & Mobile Number -->
                            <div class="py-2 d-flex flex-column">
                                <p class="ma-0 pb-2 body-heading font-weight-regular light-grey-color-text" align="left">{{ $t("Customer.CartPage.Deliver_To")}}*</p>
                                
                                <v-text-field solo flat :placeholder="$store.state.locale==='en' ? 'Enter Name' : 'Enter Name (mm)'" v-model="editAddressData.delivered_to_name" 
                                    hide-details="auto" class="ma-0 pa-0 body-text" outlined
                                    @click="" @keyup.enter="" @click:append=""
                                ></v-text-field>
                            </div>

                            <div class="py-2 d-flex flex-column">
                                <p class="ma-0 pb-2 body-heading font-weight-regular light-grey-color-text" align="left">{{ $t("Customer.CartPage.Mobile_Number")}}*</p>
                                
                                <v-text-field 
                                    prefix="+95" 
                                    solo flat placeholder="09*********" v-model="editAddressData.mobile_number" 
                                    hide-details="auto" class="ma-0 pa-0 body-text" outlined
                                    @click="" @keyup.enter="" @click:append=""
                                    :rules="$store.state.locale == 'en' ? mobileNumberRules : mobileNumberRulesMM"
                                ></v-text-field>
                            </div>

                            <!-- House no -->
                            <div class="py-2">
                                <p class="ma-0 pb-2 body-heading font-weight-regular light-grey-color-text" align="left">{{ $t("Customer.CartPage.House_Number_Building_Name")}}*</p>

                                <v-text-field solo flat :placeholder="$store.state.locale==='en' ? 'Enter Address Line' : 'Enter Address Line (mm)'" v-model="editAddressData.house_number" 
                                    hide-details="auto" class="ma-0 pa-0 body-text" outlined
                                    @click="" @keyup.enter="" @click:append=""
                                ></v-text-field>
                            </div>

                            <!-- Street Name / Locality -->
                            <div class="py-2 d-flex flex-column">
                                <p class="ma-0 pb-2 body-heading font-weight-regular light-grey-color-text" align="left">{{ $t("Customer.CartPage.Street_Name_Locality")}}*</p>
                                
                                <v-text-field solo flat :placeholder="$store.state.locale==='en' ? 'Enter Street Name' : 'Enter Street Name (mm)'" v-model="editAddressData.street_address" 
                                    hide-details="auto" class="ma-0 pa-0 body-text" outlined
                                    @click="" @keyup.enter="" @click:append=""
                                ></v-text-field>
                            </div>

                            <!-- Landmark -->
                            <div class="py-2">
                                <p class="ma-0 pb-2 body-heading font-weight-regular light-grey-color-text" align="left">{{ $t("Customer.CartPage.Landmark")}}</p>

                                <v-text-field solo flat :placeholder="$store.state.locale==='en' ? 'Enter Landmark' : 'Enter Landmark (mm)'" v-model="editAddressData.landmark" 
                                    hide-details="auto" class="ma-0 pa-0 body-text" outlined
                                    @click="" @keyup.enter="" @click:append=""
                                ></v-text-field>
                            </div>

                            <!-- State -->
                            <div class="py-2">
                                <p class="ma-0 pb-2 body-heading font-weight-regular light-grey-color-text" align="left">{{ $t("Customer.CartPage.State")}}*</p>
                                <v-select :placeholder="$store.state.locale==='en' ? 'Select State / Township' : 'Select State / Township (mm)'" class="ma-0 body-text rounded-lg state-segment" 
                                    solo flat outlined
                                    :items="addressStateList"
                                    item-text="name" item-value="id"
                                    v-model="editAddressData.state"
                                    return-object
                                >
                                </v-select>
                            </div>

                            <!-- Address Type -->
                            <div>
                                <div class="">
                                    <p class="ma-0 body-heading font-weight-regular light-grey-color-text" align="left">{{ $t("Customer.CartPage.Address_Type")}}</p>
                                </div>

                                <div class="py-2 d-flex justify-space-between align-center flex-wrap">
                                    <div class="pr-2 one-third-width">
                                        <button class="mr-2 px-2 py-2 d-flex justify-center align-center rounded full-width-button" :class="[editAddressData.address_type_name != null && editAddressData.address_type_name == 'Home' ? 'light-blue-border light-blue-color-text' : 'light-grey-border dark-grey-color-text']" @click="editAddressData.address_type_name='Home'">
                                            <img :src="editAddressData.address_type_name != null && editAddressData.address_type_name == 'Home' ? 'https://s3iconimages.mymedicine.com.mm/AddressHomeIcon_LightBlue.svg' : 'https://s3iconimages.mymedicine.com.mm/AddressHomeIcon.svg'" alt="home-icon">
                                            <p class="ma-0 pl-2 body-text font-weight-medium">{{ $t("Customer.CartPage.Home")}}</p>
                                        </button>
                                    </div>
                                    
                                    <div class="px-2 one-third-width">
                                        <button 
                                            class="mr-2 px-2 py-2 d-flex justify-center align-center rounded full-width-button" 
                                            :class="[editAddressData.address_type_name != null && editAddressData.address_type_name == 'Work' ? 'light-blue-border light-blue-color-text' : 'light-grey-border dark-grey-color-text']" 
                                            @click="editAddressData.address_type_name='Work'">
                                            <img :src="editAddressData.address_type_name != null && editAddressData.address_type_name == 'Work' ? 'https://s3iconimages.mymedicine.com.mm/AddressWorkIcon_LightBlue.svg' : 'https://s3iconimages.mymedicine.com.mm/AddressWorkIcon.svg'" alt="home-icon">
                                            <p class="ma-0 pl-2 body-text font-weight-medium">{{ $t("Customer.CartPage.Work")}}</p>
                                        </button>
                                    </div>

                                    <div class="pl-2 one-third-width">
                                        <button 
                                            class="mr-2 px-2 py-2 d-flex justify-center align-center rounded full-width-button" 
                                            :class="[editAddressData.address_type_name != null && editAddressData.address_type_name != 'Home' && editAddressData.address_type_name != 'Work' ? 'light-blue-border light-blue-color-text' : 'light-grey-border dark-grey-color-text']" 
                                            @click="editAddressData.address_type_name='Other'">
                                            <p class="ma-0 pl-2 body-text font-weight-medium">{{ $t("Customer.CartPage.Others")}}</p>
                                        </button>
                                    </div>
                                </div>

                                <div v-if="editAddressData.address_type_name != 'Home' && editAddressData.address_type_name != 'Work'" class="py-2">
                                    <v-text-field solo flat placeholder="Enter a Name" v-model="editAddressData.address_type_name" hide-details="auto" 
                                        class="ma-0 pa-0 body-text light-grey-border"
                                        @click="" @keyup.enter="" @click:append=""
                                    ></v-text-field>
                                </div>
                            </div>
                        </div>

                        <v-card-actions class="ma-0 pa-0 d-flex justify-center reduce_top" >
                            <div class="mb-4 pb-4 full-width-button d-flex justify-space-around align-center flex-grow-1 reduce_top">
                                <div class="">
                                    <v-btn  
                                        elevation="0"
                                        class=" body-text font-weight-semi-bold text-transform-none-class cancel_Button small-button" 
                                        @click="editAddressPopupClose()" 
                                        :disabled="editAddressClickedFlag">
                                        <span class="px-2 py-2 body-text white-color-text font-weight-semi-bold">{{ $t("Customer.CartPage.Cancel")}}</span>
                                    </v-btn>
                                </div>
                                <div class="">
                                    <v-btn 
                                        elevation="0" class="editAddressButton body-text font-weight-semi-bold text-transform-none-class small-button" 
                                        @click="editAddress()"
                                        :disabled=" editAddressClickedFlag || 
                                                    !(editAddressData.delivered_to_name && 
                                                    (editAddressData.mobile_number && editAddressData.mobile_number.length >= 7 && editAddressData.mobile_number.length <= 11 && editAddressData.mobile_number.toString()[0] == '0') && 
                                                    editAddressData.house_number && editAddressData.street_address && editAddressData.state && editAddressData.address_type_name
                                                    )"
                                        :loading=" editAddressClickedFlag"
                                        >
                                        <span class="px-4 py-2 body-text white-color-text font-weight-semi-bold">{{ $t("Customer.CartPage.Save_Changes")}}</span>
                                    </v-btn>
                                </div>
                            </div>
                        </v-card-actions>
                    </div>
                </v-card>
            </v-dialog>
            <!-- Edit Address Popup End -->

            <!-- Out of Stock Dialog Start -->
            <v-dialog v-model="outOfStockFlag" persistent :max-width="outOfStockWidth">
                <v-card>
                    <v-card-title>
                        <div class="d-flex flex-column justify-center align-center full-width-button" align="center">
                            <img class="cart-updated-icon" src="https://s3iconimages.mymedicine.com.mm/cart_updated_icon.svg" alt="cart-updated-icon">
                            <p class="ma-0 body-heading font-weight-bold">{{ $t("Customer.CartPage.Cart_Updated")}}</p>
                            <p class="ma-0 light-grey-color-text body-heading font-weight-regular">{{ $t("Customer.CartPage.Some_items_in_your_cart_have_been_updated")}}.</p>
                        </div>
                    </v-card-title>

                    <div class="pa-4">
                        <!-- out of Stock Message -->
                        <p class="ma-0 pb-2 body-text font-weight-semi-bold" align="left">{{ $t("Customer.CartPage.Products")}}</p>

                        <div class="pt-2">
                            <ul v-for="product, index in outOfStockProductsList" :key="index">
                                <li class="body-text font-weight-medium" align="left">
                                    {{ product.product_name }} {{ product.variant_value }}
                                </li>
                            </ul>
                        </div>
                    </div>

                    <v-card-actions class="ma-0 pa-0">
                        <div class="pa-4 d-flex full-width-button justify-center align-center">
                            <button class="mt-2 mx-4 px-4 py-2 full-width-button rounded light-blue-background edit-address-action-button" @click="outOfStockPopupClose()">
                                <span v-if="!outOfStockLoader" :disabled="outOfStockLoader" class="px-2 py-2 body-text white-color-text font-weight-semi-bold">{{ $t("Customer.CartPage.Continue")}}</span>
                                <v-progress-circular v-else color="white"></v-progress-circular>
                            </button>
                        </div>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <!-- Out of Stock Dialog End -->

            <!-- Step 2 API Error Start -->
            <v-dialog v-model="somethingWentWrong" persistent max-width="600">
                <v-card>
                    <v-card-title>
                        <p class="ma-0 body-heading font-weight-bold" align="left">{{ $t("Customer.CartPage.Error")}}</p>
                    </v-card-title>

                    <v-divider></v-divider>

                    <p class="ma-0 px-4 pt-4 body-text font-weight-medium" align="left">{{ $t("Customer.CartPage.Something_went_wrong_while_updating_address_details_Please_try_again")}}</p>

                    <v-card-actions class="pa-4 d-flex justify-center ma-0 pa-0">
                        <button class="px-4 py-2 rounded-lg one-third-width body-heading font-weight-medium light-blue-background white-color-text" @click="resetAddressStep()">{{ $t("Customer.CartPage.OK")}}</button>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <!-- Step 2 API Error End -->

            <!-- Header Segment -->
			<div>
				<common-home-header :hide_cart="true" :callpermissionflag="callpermissionflag" :authDialogue="auth_error_enable" :errorDialogue="error_enable"></common-home-header>
                <v-divider></v-divider>
			</div>

            <!-- Web Navigation Tabs -->
            <div class="navigation-tabs-segment">
                <header-tabs-component :tab_id="1" :is_user_logged_in="isUserLoggedIn"></header-tabs-component>
                <v-divider></v-divider>
            </div>

            <!-- Mobile Header -->
            <div class="mobile-header-segment">
                <p class="ma-0  pa-4 body-heading font-weight-semi-bold d-flex justify-left" align="left">
                    <img class="mr-2 clickable-pointer" width="22px" height="22px" src="https://s3iconimages.mymedicine.com.mm/goToArrowIcon.svg" 
                        alt="Back Arrow" @click="goBackFromCartPage()">
                        {{ $t("Customer.CartPage.Cart")}}
                </p>

                <v-divider></v-divider>
            </div>

            <!-- Main Page Segment -->
            <div class="main-segment-background">
                <div class="container">
                    <div v-if="skeletonLoaderFlag">
                        <div class="loader-web">
                            <div class="d-flex flex-column flex-lg-row justify-lg-space-between">
                                <div class="mr-2 pa-4 order-flow-segment white-background">
                                    <v-skeleton-loader elevation="0" type="list-item-avatar" class="py-2"></v-skeleton-loader>
                                    <v-skeleton-loader elevation="0" type="image" class="pa-2"></v-skeleton-loader>

                                    <v-skeleton-loader elevation="0" type="list-item-avatar" class="py-2"></v-skeleton-loader>
                                    <v-skeleton-loader elevation="0" type="list-item-avatar" class="py-2"></v-skeleton-loader>
                                    <v-skeleton-loader elevation="0" type="list-item-avatar" class="py-2"></v-skeleton-loader>
                                </div>

                                <div class="order-summary-segment">
                                    <div class="pa-4 rounded-lg white-background">
                                        <v-skeleton-loader elevation="0" type="heading" class="py-2"></v-skeleton-loader>
                                        <v-skeleton-loader elevation="0" type="image" class="py-2"></v-skeleton-loader>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="loader-mobile">
                            <div class="pa-4">
                                <v-skeleton-loader elevation="0" type="list-item-avatar" class="py-2"></v-skeleton-loader>
                                <v-skeleton-loader elevation="0" type="image" class="px-6 py-2"></v-skeleton-loader>

                                <v-skeleton-loader elevation="0" type="list-item-avatar" class="py-2"></v-skeleton-loader>
                                <v-skeleton-loader elevation="0" type="list-item-avatar" class="py-2"></v-skeleton-loader>
                                <v-skeleton-loader elevation="0" type="list-item-avatar" class="py-2"></v-skeleton-loader>
                            </div>

                            <div class="light-grey-dashed-border dashed-border-line"></div>

                            <div class="pa-4">
                                <v-skeleton-loader elevation="0" type="heading" class="py-2"></v-skeleton-loader>
                                <v-skeleton-loader elevation="0" type="image" class="py-2"></v-skeleton-loader>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <!-- Empty Cart -->
                        <div v-if="emptyCartFlag" class="rounded-lg white-background">
                            <!-- Heading -->
                            <p class="pa-4 ma-0 pb-2 body-heading font-weight-semi-bold cart-heading" align="left">{{ $t("Customer.CartPage.Cart")}}</p>

                            <div class="d-flex justify-center align-center empty-cart-segment">
                                <div class="pa-4 empty-cart-web-width">
                                    <div class="">
                                        <img src="https://s3iconimages.mymedicine.com.mm/empty_cart_icon.svg" alt="empty-cart-icon">
                                        <p class="ma-0 py-2 title-heading font-weight-semi-bold">{{ $t("Customer.CartPage.Your_cart_is_empty")}}</p>

                                        <p class="ma-0 py-2 body-text font-weight-regular">{{ $t("Customer.CartPage.Your_wellness_journey_starts_now_Discover_our_wide_range_of_products")}}</p>

                                        <div class="py-2 empty-cart-actions-web">
                                            <button class="mb-2 px-4 py-2 rounded-lg empty-cart-action-button body-heading font-weight-medium light-blue-background white-color-text" @click="redirectTo('AllCategoriesPage')">{{ $t("Customer.CartPage.Explore_Categories")}}</button>
                                            <button class="mt-2 ml-4 px-4 py-2 rounded-lg empty-cart-action-button body-heading font-weight-medium light-blue-border light-blue-color-text" @click="redirectTo('CommonHomePage')">{{ $t("Customer.CartPage.Go_Home")}}</button>
                                        </div>
                                        <div class="py-2 empty-cart-actions-mobile">
                                            <button class="mb-2 px-4 py-2 rounded-lg full-width-button body-heading font-weight-medium light-blue-background white-color-text"  @click="redirectTo('AllCategoriesPage')">{{ $t("Customer.CartPage.Explore_Categories")}}</button>
                                            <button class="mt-2 px-4 py-2 rounded-lg full-width-button body-heading font-weight-medium light-blue-border light-blue-color-text" @click="redirectTo('CommonHomePage')">{{ $t("Customer.CartPage.Go_Home")}}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else class="d-flex flex-column flex-lg-row justify-lg-space-between">
                            <!-- Order Flow Segment -->
                            <div class="mr-2 pa-4 order-flow-segment white-background">
                                <div>
                                    <!-- Steps -->
                                    <div class="pt-2">
                                        <!-- Step 1: Review Products & their Quantity -->
                                        <div id="step-1" class="mb-4 rounded-lg" :class="[step1Flag ? 'light-grey-border' : '']">
                                            <!-- Step 1 header -->
                                            <div class="px-4 py-2 d-flex justify-space-between align-center grey-background" :class="[step1Flag ? 'rounded-lg rounded-bl-0 rounded-br-0' : 'rounded-lg']"  @click="step1Flag=!step1Flag">
                                                <div class="d-flex align-center">
                                                    <p class="ma-0 font-16px font-weight-semi-bold white-color-text step-number" :class="[step1Flag || step1Completed ? 'dark-blue-background' : 'dark-grey-background']">1</p>
                                                    <p class="ma-0 pl-2 body-heading font-weight-semi-bold dark-blue-color-text" style="text-transform: uppercase;" align="left">{{ $t("Customer.CartPage.Cart")}}</p>
                                                </div>

                                                <img v-if="step1Completed" class="clickable-pointer" src="https://s3iconimages.mymedicine.com.mm/dropdown_blue_icon.svg" alt="dropdown-icon">
                                            </div>

                                            <!-- Step 1 Content -->
                                            <div class="pa-4 d-flex flex-column" v-if="step1Flag && cartData.products && cartData.products.length > 0">
                                                <!-- Cart Quantity -->
                                                <div class="pb-2">
                                                    <p class="ma-0 body-text font-weight-semi-bold light-grey-color-text" style="text-transform: uppercase;" align="left">{{ cartData.products.length }} {{ $t("Customer.CartPage.Items_in_cart")}}</p>
                                                </div>

                                                <!-- Products -->
                                                <div v-if="cartData.products.length > 0" class="py-2 d-flex flex-column">
                                                    <!-- Products -->
                                                    <div class="pt-2">
                                                        <div v-for="product, index in cartData.products" :key="index">
                                                            <cart-product-component :key="quantityComponent" @handleRemoveProduct="handleRemoveProduct" @totalPriceUpdate="totalPriceUpdate" @updatingCart="updatingCart" class="product-component" :product_data="product" quantity_editable="true" :total_price="totalPrice"></cart-product-component>
                                                            <div v-if="index < cartData.products.length - 1" class="py-2">
                                                                <v-divider></v-divider>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <!-- Continue Button - Continue (to Address)-->
                                                <div class="pt-4">
                                                    <v-btn elevation="0" :disabled="step1FlagLoader || isLoading"
                                                        :style="step1FlagLoader || isLoading ? {backgroundColor:'#F7F8FA !important', color:'#828282 !important'} : {backgroundColor:'#48ACEF !important', color:'white !important'}"
                                                        :class="{ 'disabled-button': !(step1FlagLoader || isLoading), 'active-button': (step1FlagLoader || isLoading) }"
                                                        class="ma-0 px-4 py-4 rounded body-heading font-weight-semi-bold full-width-button text-transform-none-class" 
                                                        @click="step1Completed=true; step1Flag=false; step2Flag=true;scrollIntoView('step-2')"
                                                        :loading="step1FlagLoader || isLoading"
                                                    >
                                                        <span class="white-color-text">{{ $t("Customer.CartPage.Continue")}}</span>
                                                    </v-btn>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- Step 2: Address -->
                                        <div id="step-2" class="my-4 rounded-lg" :class="[step2Flag ? 'light-grey-border' : '']">
                                            <!-- Step 2 header -->
                                            <div class="px-4 py-2 d-flex justify-space-between align-center grey-background" :class="[step2Flag ? 'rounded-lg rounded-bl-0 rounded-br-0' : 'rounded-lg']" @click="step1Completed ? step2Flag=!step2Flag : step2Flag=false">
                                                <div class="d-flex">
                                                    <p class="ma-0 font-16px font-weight-semi-bold white-color-text step-number" :class="[step2Flag || step2Completed ? 'dark-blue-background' : 'dark-grey-background']">2</p>
                                                    <p class="ma-0 pl-2 body-heading font-weight-semi-bold" :class="[step2Flag || step2Completed ? 'dark-blue-color-text' : 'light-grey-color-text']" align="left" style="text-transform: uppercase;">{{ $t("Customer.CartPage.Address")}}</p>
                                                </div>

                                                <img v-if="step2Completed" class="clickable-pointer" src="https://s3iconimages.mymedicine.com.mm/dropdown_blue_icon.svg" alt="dropdown-icon">
                                            </div>

                                            <!-- Step 2 Content -->
                                            <div class="pa-4" v-if="step2Flag">
                                                <!-- Shipping Address -->
                                                <div class="pb-4 address-list-section">
                                                    <p class="mb-2 body-heading font-weight-semi-bold light-grey-color-text" align="left">{{ $t("Customer.CartPage.Shipping_Address")}}</p>

                                                    <div class="shipping-address-web-component">
                                                        <div class="pa-4 rounded-lg light-grey-border">
                                                            <label v-if="cartData.addresses.length > 0" style="top:auto;" align="left" v-for="address, index in cartData.addresses" :key="index" @change="scrollIntoView('delivery-options')" @click="checkIfAddressIsDeliverable(address.is_deliverable)">
                                                                <!-- Existing Addresses -->
                                                                <div v-if="!address.add_address" class="address-radio-item clickable-pointer">
                                                                    <div class="d-flex justify-space-between align-center">
                                                                        <div>
                                                                            <input type="radio" :id="index" :label="address.address_unique_id" :value="address.address_unique_id" v-model="selectedShippingAddressId" align="left">
                                                                            <span class="pl-2 body-heading font-weight-medium">{{ address.address_type_name }}</span>
                                                                        </div>

                                                                        <div class="d-flex align-center">
                                                                            <div class="px-4 py-2 pr-3 d-flex align-center">
                                                                                <div v-if="!editAddressPopupLoader">
                                                                                    <v-btn icon v-on:click.stop="editAddressPopupOpen(index);" :disabled="editAddressPopupLoader">
                                                                                        <img src="https://s3iconimages.mymedicine.com.mm/edit_icon_black.svg" alt="edit-icon">
                                                                                    </v-btn>
                                                                                </div>
                                                                                <div v-else>
                                                                                    <v-progress-circular size="20" indeterminate color="primary"></v-progress-circular>
                                                                                </div>
                                                                                
                                                                                <!-- <span class="body-text font-weight-regular pl-2">Edit</span> -->
                                                                            </div>
                                                                            
                                                                            <!-- <div class="pl-3 d-flex align-center">
                                                                                <img src="https://s3iconimages.mymedicine.com.mm/delete_icon_red.svg" alt="delete-icon">
                                                                                <span class="pl-2 body-text font-weight-regular light-red-color-text">Remove</span>
                                                                            </div> -->
                                                                        </div>
                                                                    </div>

                                                                    <div class="pa-4">
                                                                        <p class="ma-0 body-text font-weight-regular light-grey-color-text" align="left">{{ address.delivered_to_name }},</p>
                                                                        <p class="ma-0 body-text font-weight-regular light-grey-color-text" align="left">
                                                                            {{ address.house_number }}, {{ address.street_address }}, {{ address.state_name }}
                                                                        </p>
                                                                    </div>

                                                                    <div v-if="selectedShippingAddressId == address.address_unique_id && !address.is_deliverable" class="px-4 py-2 d-flex align-center rounded light-red-tint-background">
                                                                        <img src="https://s3iconimages.mymedicine.com.mm/delivery_unavailable_emoji.svg" alt="delivery-unavailable">
                                                                        <p class="ma-0 pl-4 light-red-color-text body-text font-weight-regular">
                                                                            {{ $t("Customer.CartPage.Delivery_not_available_for_selected_address_Please_change_the_address_or_state_and_try_again")}}
                                                                        </p>
                                                                    </div>

                                                                    <div class="py-4">
                                                                        <v-divider></v-divider>
                                                                    </div>
                                                                </div>

                                                                <!-- Add New Address -->
                                                                <div v-else class="address-radio-item clickable-pointer" align="left">
                                                                    <input type="radio" :id="index" :label="address.address_unique_id" :value="address.address_type_name" v-model="selectedShippingAddressId" align="left">
                                                                    <strong class="pl-2 body-heading font-weight-medium">{{ $t("Customer.CartPage.Add_Address")}}</strong>
                                                                    
                                                                    <div v-if="selectedShippingAddressId == 'new'" class="px-4 pt-2" @click.prevent="(e) => {e.preventDefault();}">
                                                                        <div>
                                                                            <!-- Deliver To & Mobile Number -->
                                                                            <div class="pb-2 d-flex flex-wrap">
                                                                                <div class="pr-2 half-width d-flex flex-column">
                                                                                    <p class="ma-0 pb-2 body-heading font-weight-regular light-grey-color-text">{{ $t("Customer.AddAddress.Deliver_To")}}*</p>
                                                                                    
                                                                                    <v-text-field 
                                                                                        solo flat :placeholder="$store.state.locale==='en' ? 'Enter Name' : 'Enter Name (mm)'" v-model="addAddressData.delivered_to_name" 
                                                                                        hide-details="auto" class="ma-0 pa-0 body-text" outlined
                                                                                        @click="" @keyup.enter="" @click:append=""
                                                                                    ></v-text-field>
                                                                                </div>

                                                                                <div class="pl-2 half-width d-flex flex-column">
                                                                                    <p class="ma-0 pb-2 body-heading font-weight-regular light-grey-color-text">{{ $t("Customer.AddAddress.Mobile_Number")}}*</p>
                                                                                    
                                                                                    <v-text-field 
                                                                                        prefix="+95" 
                                                                                        type="number" solo flat placeholder="09*********"
                                                                                        v-model="addAddressData.phone_number" hide-details="auto" class="ma-0 pa-0 body-text" outlined
                                                                                        @click="" @keyup.enter="" @click:append=""
                                                                                        :rules="$store.state.locale == 'en' ? mobileNumberRules : mobileNumberRulesMM"
                                                                                    ></v-text-field>
                                                                                </div>
                                                                            </div>

                                                                            <!-- House no -->
                                                                            <div style="padding-bottom: 20px">
                                                                                <p class="ma-0 pb-2 body-heading font-weight-regular light-grey-color-text">{{ $t("Customer.AddAddress.House_Number_Building_Name")}}*</p>

                                                                                <v-text-field solo flat :placeholder="$store.state.locale==='en' ? 'Enter Address Line' : 'Enter Address Line (mm)'" v-model="addAddressData.house_number" 
                                                                                    hide-details="auto" class="ma-0 pa-0 body-text" outlined
                                                                                    @click="" @keyup.enter="" @click:append=""
                                                                                ></v-text-field>
                                                                            </div>

                                                                            <!-- Street Name / Locality -->
                                                                            <div class="pb-2 d-flex flex-wrap">
                                                                                <div class="pr-2 half-width d-flex flex-column">
                                                                                    <p class="ma-0 pb-2 body-heading font-weight-regular light-grey-color-text">{{ $t("Customer.AddAddress.Street_Name_Locality")}}*</p>
                                                                                    
                                                                                    <v-text-field solo flat :placeholder="$store.state.locale==='en' ? 'Enter Street Name' : 'Enter Street Name (mm)'" v-model="addAddressData.street_address" 
                                                                                        hide-details="auto" class="ma-0 pa-0 body-text" outlined
                                                                                        @click="" @keyup.enter="" @click:append=""
                                                                                    ></v-text-field>
                                                                                </div>

                                                                                <div class="pl-2 half-width d-flex flex-column">
                                                                                    <p class="ma-0 pb-2 body-heading font-weight-regular light-grey-color-text">{{ $t("Customer.AddAddress.Landmark")}}</p>

                                                                                    <v-text-field solo flat :placeholder="$store.state.locale==='en' ? 'Enter Landmark' : 'Enter Landmark (mm)'" v-model="addAddressData.landmark" 
                                                                                        hide-details="auto" class="ma-0 pa-0 body-text" outlined
                                                                                        @click="" @keyup.enter="" @click:append=""
                                                                                    ></v-text-field>
                                                                                </div>
                                                                            </div>

                                                                            <!-- State & Township -->
                                                                            <div class="py-2">
                                                                                <div class="full-width d-flex flex-column">
                                                                                    <p class="ma-0 pb-2 body-heading font-weight-regular light-grey-color-text">{{ $t("Customer.AddAddress.State")}}*</p>
                                                                                    <v-select :placeholder="$store.state.locale==='en' ? 'Select State / Township' : 'Select State / Township (mm)'" class="ma-0 body-text rounded state-segment" solo flat outlined
                                                                                        :items="addressStateList"
                                                                                        item-text="name" item-value="id"
                                                                                        return-object
                                                                                        v-model="addAddressData.state"
                                                                                    >
                                                                                        <template #append>
                                                                                            <img class="mt-1" src="https://s3iconimages.mymedicine.com.mm/StateDropdownIcon.png" alt="Search Icon">
                                                                                        </template>
                                                                                    </v-select>
                                                                                </div>
                                                                            </div>

                                                                            <!-- Address Type -->
                                                                            <div>
                                                                                <div class="py-2">
                                                                                    <p class="ma-0 body-heading font-weight-regular light-grey-color-text">{{ $t("Customer.AddAddress.Address_Type")}}</p>
                                                                                </div>

                                                                                <div class="py-2 d-flex justify-space-between align-center flex-wrap">
                                                                                    <div class="pr-2 one-third-width">
                                                                                        <button 
                                                                                            class="mr-2 px-2 py-2 d-flex justify-center align-center rounded full-width-button" 
                                                                                            :class="[selectedShippingAddressType != null && selectedShippingAddressType == 'Home' ? 'light-blue-border light-blue-color-text' : 'light-grey-border dark-grey-color-text']" 
                                                                                            @click="selectedShippingAddressType='Home'"
                                                                                            >
                                                                                            <img :src="selectedShippingAddressType != null && selectedShippingAddressType == 'Home' ? 'https://s3iconimages.mymedicine.com.mm/AddressHomeIcon_LightBlue.svg' : 'https://s3iconimages.mymedicine.com.mm/AddressHomeIcon.svg'" alt="home-icon">
                                                                                            <p class="ma-0 pl-2 d-flex align-center body-text font-weight-medium">{{ $t("Customer.AddAddress.Home")}}</p>
                                                                                        </button>
                                                                                    </div>
                                                                                    
                                                                                    <div class="px-2 one-third-width">
                                                                                        <button 
                                                                                            class="mr-2 px-2 py-2 d-flex justify-center align-center rounded full-width-button" 
                                                                                            :class="[selectedShippingAddressType != null && selectedShippingAddressType == 'Work' ? 'light-blue-border light-blue-color-text' : 'light-grey-border dark-grey-color-text']" 
                                                                                            @click="selectedShippingAddressType='Work'"
                                                                                            >
                                                                                            <img :src="selectedShippingAddressType != null && selectedShippingAddressType == 'Work' ? 'https://s3iconimages.mymedicine.com.mm/AddressWorkIcon_LightBlue.svg' : 'https://s3iconimages.mymedicine.com.mm/AddressWorkIcon.svg'" alt="home-icon">
                                                                                            <p class="ma-0 pl-2 body-text font-weight-medium">{{ $t("Customer.AddAddress.Work")}}</p>
                                                                                        </button>
                                                                                    </div>

                                                                                    <div class="pl-2 one-third-width">
                                                                                        <button 
                                                                                            class="mr-2 px-2 py-2 d-flex justify-center align-center rounded full-width-button" 
                                                                                            :class="[selectedShippingAddressType != null && selectedShippingAddressType != 'Home' && selectedShippingAddressType != 'Work' ? 'light-blue-border light-blue-color-text' : 'light-grey-border dark-grey-color-text']" 
                                                                                            @click="selectedShippingAddressType='Other'">
                                                                                            <p class="ma-0 body-text font-weight-medium">{{ $t("Customer.AddAddress.Others")}}</p>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>

                                                                                <div v-if="selectedShippingAddressType == 'Other'" class="py-2">
                                                                                    <v-text-field 
                                                                                        solo flat placeholder="Enter a Name" 
                                                                                        v-model="shippingOtherAddressTypeName" hide-details="auto" class="ma-0 pa-0 light-grey-border"
                                                                                        @click="" @keyup.enter="" @click:append=""
                                                                                    ></v-text-field>
                                                                                </div>
                                                                            </div>

                                                                            <!-- Actions - Save Changes (Web)-->
                                                                            <div class="pt-2 d-flex justify-left">
                                                                                <v-btn class="AddAddressButton body-text font-weight-semi-bold text-transform-none-class" elevation="0" 
                                                                                        @click="addAddressClicked ? null : addAddress();" 
                                                                                        :disabled="addAddressClicked || !(addAddressData.delivered_to_name && (addAddressData.phone_number && addAddressData.phone_number.length >= 7 && addAddressData.phone_number.length <= 11 && addAddressData.phone_number.toString()[0] == '0') && addAddressData.street_address && addAddressData.house_number && addAddressData.state && (selectedShippingAddressType == 'Home' || selectedShippingAddressType == 'Work' || (selectedShippingAddressType == 'Other' && shippingOtherAddressTypeName)) )"
                                                                                    >
                                                                                    <div v-if="!addAddressClicked">
                                                                                        <p class="ma-0 body-text font-weight-medium">{{ $t("Customer.CartPage.Save_Changes")}}</p>
                                                                                    </div>
                                                                                    <div v-else>
                                                                                        <v-progress-circular size="20" indeterminate color="white"></v-progress-circular>
                                                                                    </div>
                                                                                </v-btn>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <!-- MOBILE: -->
                                                    <div class="shipping-address-mobile-component">
                                                        <!-- Existing Addresses -->
                                                        <label v-if="cartData.addresses.length > 0" align="left" v-for="address, index in cartData.addresses" :key="index" @change="scrollIntoView('delivery-options')">
                                                            <div v-if="!address.add_address" class="pa-2 my-4 rounded-lg light-grey-border address-radio-item" @click="checkIfAddressIsDeliverable(address.is_deliverable)">
                                                                <div class="d-flex justify-space-between align-center">
                                                                    <div class="d-flex align-center">
                                                                        <input type="radio" :id="index" :label="address.address_unique_id" :value="address.address_unique_id" v-model="selectedShippingAddressId" align="left">
                                                                        <strong class="pl-2 body-heading font-weight-medium">{{ address.address_type_name }}</strong>
                                                                    </div>

                                                                    <div class="d-flex align-center">
                                                                        <div class="px-4 py-2 pr-3 d-flex align-center">
                                                                            <div v-if="!editAddressPopupLoader">
                                                                                <v-btn icon v-on:click.stop="editAddressPopupOpen(index);"><img src="https://s3iconimages.mymedicine.com.mm/edit_icon_black.svg" alt="edit-icon"></v-btn>
                                                                            </div>
                                                                            <div v-else>
                                                                                <v-progress-circular size="20" indeterminate color="primary"></v-progress-circular>
                                                                            </div>
                                                                            <!-- <span class="body-text font-weight-regular pl-2">Edit</span> -->
                                                                        </div>
                                                                        
                                                                        <!-- <div class="pl-3 d-flex align-center">
                                                                            <img src="https://s3iconimages.mymedicine.com.mm/delete_icon_red.svg" alt="delete-icon">
                                                                            <span class="pl-2 body-text font-weight-regular light-red-color-text">Remove</span>
                                                                        </div> -->
                                                                    </div>
                                                                </div>

                                                                <div class="px-4 py-2">
                                                                    <p class="ma-0 body-text font-weight-regular light-grey-color-text" align="left">{{ address.delivered_to_name }},</p>
                                                                    <p class="ma-0 body-text font-weight-regular light-grey-color-text" align="left">
                                                                        {{ address.house_number }}, {{ address.street_address }}, {{ address.state_name }}
                                                                    </p>
                                                                </div>

                                                                <div v-if="selectedShippingAddressId == address.address_unique_id && !address.is_deliverable" class="px-4 py-2 d-flex align-center rounded light-red-tint-background">
                                                                    <img src="https://s3iconimages.mymedicine.com.mm/delivery_unavailable_emoji.svg" alt="delivery-unavailable">
                                                                    <p class="ma-0 pl-4 light-red-color-text body-text font-weight-regular">{{ $t("Customer.CartPage.Delivery_not_available_for_selected_address_Please_change_the_address_or_state_and_try_again")}}</p>
                                                                </div>

                                                                <!-- <div class="py-2">
                                                                    <v-divider></v-divider>
                                                                </div>

                                                                <div class="d-flex justify-center align-center">
                                                                    <div class="pl-3 d-flex align-center">
                                                                        <img src="https://s3iconimages.mymedicine.com.mm/delete_icon_red.svg" alt="delete-icon">
                                                                        <span class="pl-2 body-text font-weight-regular light-red-color-text">Remove</span>
                                                                    </div>

                                                                    <div class="pr-3 d-flex align-center">
                                                                        <img src="https://s3iconimages.mymedicine.com.mm/edit_icon_black.svg" alt="edit-icon">
                                                                        <span class="pl-2 body-text font-weight-regular">Edit</span>
                                                                    </div>
                                                                </div> -->
                                                            </div>

                                                            <div v-else class="mb-2 pa-4 rounded-lg light-grey-border address-radio-item" align="left">
                                                                <input type="radio" autofocus="false" :id="index" :label="address.address_unique_id" :value="address.address_type_name" v-model="selectedShippingAddressId" align="left">
                                                                <strong class="pl-2 body-heading font-weight-medium">{{ $t("Customer.CartPage.Add_Address")}}</strong>
                                                                
                                                                <div v-if="selectedShippingAddressId == 'new'" class="px-4 pt-2" @click.prevent="(e) => {e.preventDefault();}">
                                                                    <div>
                                                                        <!-- Deliver To & Mobile Number -->
                                                                        <div class="pb-2">
                                                                            <div class="pb-2">
                                                                                <p class="ma-0 pb-2 body-heading font-weight-regular light-grey-color-text">{{ $t("Customer.CartPage.Deliver_To")}}*</p>
                                                                                
                                                                                <v-text-field solo flat placeholder="Enter Name" v-model="addAddressData.delivered_to_name" 
                                                                                    hide-details="auto" class="ma-0 pa-0 body-text" outlined
                                                                                    @click="" @keyup.enter="" @click:append=""
                                                                                ></v-text-field>
                                                                            </div>

                                                                            <div class="py-2">
                                                                                <p class="ma-0 pb-2 body-heading font-weight-regular body-heading light-grey-color-text">{{ $t("Customer.CartPage.Mobile_Number")}}*</p>
                                                                                
                                                                                <v-text-field 
                                                                                    prefix="+95" 
                                                                                    type="number" solo flat placeholder="Enter Mobile Number" v-model="addAddressData.phone_number" 
                                                                                    hide-details="auto" class="ma-0 pa-0 body-text" outlined
                                                                                    @click="" @keyup.enter="" @click:append=""
                                                                                    :rules="$store.state.locale == 'en' ? mobileNumberRules : mobileNumberRulesMM"
                                                                                ></v-text-field>
                                                                            </div>
                                                                        </div>

                                                                        <!-- House no -->
                                                                        <div style="padding-bottom: 15px">
                                                                            <p class="ma-0 pb-2 body-heading font-weight-regular light-grey-color-text">{{ $t("Customer.CartPage.House_Number_Building_Name")}}*</p>

                                                                            <v-text-field solo flat placeholder="Enter Address Line" v-model="addAddressData.house_number" 
                                                                                hide-details="auto" class="ma-0 pa-0 body-text" outlined
                                                                                @click="" @keyup.enter="" @click:append=""
                                                                            ></v-text-field>
                                                                        </div>

                                                                        <!-- Street Name / Locality -->
                                                                        <div class="py-2">
                                                                            <p class="ma-0 pb-2 body-heading font-weight-regular body-heading light-grey-color-text">{{ $t("Customer.CartPage.Street_Name_Locality")}}*</p>
                                                                            
                                                                            <v-text-field solo flat placeholder="Enter Street Name" v-model="addAddressData.street_address" 
                                                                                hide-details="auto" class="ma-0 pa-0 body-text" outlined
                                                                                @click="" @keyup.enter="" @click:append=""
                                                                            ></v-text-field>
                                                                        </div>

                                                                        <!-- Landmark -->
                                                                        <div class="py-2">
                                                                            <p class="ma-0 pb-2 body-heading font-weight-regular light-grey-color-text">{{ $t("Customer.CartPage.Landmark")}}</p>

                                                                            <v-text-field solo flat placeholder="Enter Landmark" v-model="addAddressData.landmark" 
                                                                                hide-details="auto" class="ma-0 pa-0 body-text" outlined
                                                                                @click="" @keyup.enter="" @click:append=""
                                                                            ></v-text-field>
                                                                        </div>

                                                                        <!-- State / Township -->
                                                                        <div class="py-2">
                                                                            <p class="ma-0 pb-2 body-heading font-weight-regular light-grey-color-text">{{ $t("Customer.CartPage.State")}}*</p>
                                                                            <v-select class="ma-0 body-text rounded state-segment" solo flat outlined
                                                                                :items="addressStateList"
                                                                                item-text="name" item-value="id"
                                                                                return-object placeholder="Select State / Township"
                                                                                v-model="addAddressData.state"
                                                                            >
                                                                                <template #append>
                                                                                    <img class="mt-1" src="https://s3iconimages.mymedicine.com.mm/StateDropdownIcon.png" alt="Search Icon">
                                                                                </template>
                                                                            </v-select>
                                                                        </div>

                                                                        <!-- Address Type -->
                                                                        <div>
                                                                            <div class="pt-2">
                                                                                <p class="ma-0 body-heading font-weight-regular light-grey-color-text">{{ $t("Customer.CartPage.Address_Type")}}</p>
                                                                            </div>

                                                                            <div class="py-2 d-flex justify-space-between align-center flex-wrap">
                                                                                <div class="pr-2 one-third-width">
                                                                                    <button class="mr-2 px-2 py-2 d-flex justify-center align-center rounded full-width-button" :class="[selectedShippingAddressType != null && selectedShippingAddressType == 'Home' ? 'light-blue-border light-blue-color-text' : 'light-grey-border dark-grey-color-text']" @click="selectedShippingAddressType='Home'">
                                                                                        <img :src="selectedShippingAddressType != null && selectedShippingAddressType == 'Home' ? 'https://s3iconimages.mymedicine.com.mm/AddressHomeIcon_LightBlue.svg' : 'https://s3iconimages.mymedicine.com.mm/AddressHomeIcon.svg'" alt="home-icon">
                                                                                        <p class="ma-0 pl-2 d-flex align-center body-text font-weight-medium">{{ $t("Customer.CartPage.Home")}}</p>
                                                                                    </button>
                                                                                </div>
                                                                                
                                                                                <div class="px-2 one-third-width">
                                                                                    <button class="mr-2 px-2 py-2 d-flex justify-center align-center rounded full-width-button" :class="[selectedShippingAddressType != null && selectedShippingAddressType == 'Work' ? 'light-blue-border light-blue-color-text' : 'light-grey-border dark-grey-color-text']" @click="selectedShippingAddressType='Work'">
                                                                                        <img :src="selectedShippingAddressType != null && selectedShippingAddressType == 'Work' ? 'https://s3iconimages.mymedicine.com.mm/AddressWorkIcon_LightBlue.svg' : 'https://s3iconimages.mymedicine.com.mm/AddressWorkIcon.svg'" alt="home-icon">
                                                                                        <p class="ma-0 pl-2 d-flex align-center body-text font-weight-medium">{{ $t("Customer.CartPage.Work")}}</p>
                                                                                    </button>
                                                                                </div>

                                                                                <div class="pl-2 one-third-width">
                                                                                    <button class="mr-2 px-2 py-2 d-flex justify-center align-center rounded full-width-button" :class="[selectedShippingAddressType != null && selectedShippingAddressType != 'Home' && selectedShippingAddressType != 'Work' ? 'light-blue-border light-blue-color-text' : 'light-grey-border dark-grey-color-text']" @click="selectedShippingAddressType='Other'">
                                                                                        <p class="ma-0 body-text font-weight-medium">{{ $t("Customer.CartPage.Others")}}</p>
                                                                                    </button>
                                                                                </div>
                                                                            </div>

                                                                            <div v-if="selectedShippingAddressType == 'Other'" class="py-2">
                                                                                <v-text-field solo flat placeholder="Enter a Name" v-model="shippingOtherAddressTypeName" hide-details="auto" class="ma-0 pa-0 body-text light-grey-border"
                                                                                    @click="" @keyup.enter="" @click:append=""
                                                                                ></v-text-field>
                                                                            </div>
                                                                        </div>

                                                                        <!-- Actions - Save Changes (Mobile)-->
                                                                        <div class="pt-2 d-flex justify-left">
                                                                            <v-btn class="AddAddressButton body-text font-weight-semi-bold text-transform-none-class" elevation="0" 
                                                                                    @click="addAddressClicked ? null : addAddress();" 
                                                                                    :disabled="addAddressClicked || !(addAddressData.delivered_to_name && (addAddressData.phone_number && addAddressData.phone_number.length >= 7 && addAddressData.phone_number.length <= 11 && addAddressData.phone_number.toString()[0] == '0') && addAddressData.street_address && addAddressData.house_number && addAddressData.state && (selectedShippingAddressType == 'Home' || selectedShippingAddressType == 'Work' || (selectedShippingAddressType == 'Other' && shippingOtherAddressTypeName)) )"
                                                                                >
                                                                                <div v-if="!addAddressClicked">
                                                                                    <p class="ma-0 body-text font-weight-medium">{{ $t("Customer.CartPage.Save_Changes")}}</p>
                                                                                </div>
                                                                                <div v-else>
                                                                                    <v-progress-circular size="20" indeterminate color="white"></v-progress-circular>
                                                                                </div>
                                                                            </v-btn>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </label>
                                                    </div>

                                                    <div id="delivery-options" class="mt-2 pa-4 rounded-lg light-grey-border" v-if="shippingAddress.address_unique_id == selectedShippingAddressId && shippingAddress.is_deliverable">
                                                        <p class="ma-0 pb-4 light-grey-color-text body-heading font-weight-semi-bold" align="left">{{ $t("Customer.CartPage.Choose_a_Delivery_Method")}}</p>

                                                        <div class="">
                                                            <label v-if="shippingAddress.delivery_details.length > 0" class="d-flex justify-flex-start align-center full-width-button" align="left" v-for="deliveryOption, index in shippingAddress.delivery_details" :key="index">
                                                                <input type="radio" :id="index" :label="deliveryOption.delivery_object_id" :value="deliveryOption.delivery_object_id" v-model="selectedShippingDeliveryOption" align="left">
                                                                <div class="pl-4 py-2 full-width-button d-flex justify-space-between align-center">
                                                                    <span :class="[deliveryOption.delivery_object_id == selectedShippingDeliveryOption ? 'font-weight-bold' : 'font-weight-regular']" class="ma-0 dark-grey-color-text body-heading">{{ deliveryOption.delivery_name }}</span>
                                                                    <span class="ma-0 body-heading font-weight-medium light-blue-color-text">{{ deliveryOption.delivery_price }} MMK</span>
                                                                </div>

                                                                <br>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <!-- Continue Button - Continue (to Order Summary)-->
                                                <div class="mt-4">
                                                    <div>
                                                        <v-btn elevation="0"
                                                                :disabled="(!addressDeliverable || addAddressStepLoader || !selectedShippingDeliveryOption)" 
                                                                :style="(!addressDeliverable || addAddressStepLoader || !selectedShippingDeliveryOption) ? {backgroundColor:'#F7F8FA !important', color:'#828282 !important'} : {backgroundColor:'#48ACEF !important', color:'white !important'}"
                                                                
                                                                :class="{ 
                                                                    'disabled-button': !(addressDeliverable && selectedShippingDeliveryOption != null) || addAddressStepLoader, 
                                                                    'active-button': addressDeliverable && selectedShippingDeliveryOption != null && !addAddressStepLoader }"
                                                                    class="ma-0 px-4 py-4 rounded body-heading font-weight-semi-bold full-width-button text-transform-none-class" 
                                                                @click="addAddressToCart()"
                                                                :loading="addAddressStepLoader"
                                                                >
                                                                {{ $t("Customer.CartPage.Continue")}}
                                                        </v-btn>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- Step 3: Order Summary -->
                                        <div id="step-3" class="my-4 rounded-lg" :class="[(step3Flag) ? 'light-grey-border' : '']">
                                            <!-- Step 3 header -->
                                            <div class="px-4 py-2 d-flex justify-space-between align-center grey-background" :class="[step3Flag ? 'rounded-lg rounded-bl-0 rounded-br-0' : 'rounded-lg']" @click="(step2Completed && addressDeliverable) ? step3Flag=!step3Flag : step3Flag=false">
                                                <div class="d-flex">
                                                    <p class="ma-0 font-16px font-weight-semi-bold white-color-text step-number" :class="[((step3Flag || step3Completed) && addressDeliverable) ? 'dark-blue-background' : 'dark-grey-background']">3</p>
                                                    <p class="ma-0 pl-2 body-heading font-weight-semi-bold" :class="[((step3Flag || step3Completed) && addressDeliverable) ? 'dark-blue-color-text' : 'light-grey-color-text']" style="text-transform: uppercase;" align="left">{{ $t("Customer.CartPage.Order_Summary")}}</p>
                                                </div>

                                                <img v-if="step3Completed" class="clickable-pointer" src="https://s3iconimages.mymedicine.com.mm/dropdown_blue_icon.svg" alt="dropdown-icon">
                                            </div>

                                            <!-- Step 3 Content -->
                                            <div class="pa-4" v-if="step3Flag && addressDeliverable">
                                                <!-- Cart Quantity -->
                                                <div class="pb-2">
                                                    <p class="ma-0 body-text font-weight-semi-bold light-grey-color-text" style="text-transform: uppercase;" align="left">{{ cartData.products.length }} {{ $t("Customer.CartPage.Items_in_cart")}}</p>
                                                </div>

                                                <!-- Products -->
                                                <div v-if="cartData.products.length > 0" class="py-2 d-flex flex-column">
                                                    <!-- Products -->
                                                    <div class="pt-2">
                                                        <div v-for="product, index in cartData.products" :key="index">
                                                            <cart-product-component :key="quantityComponent" @handleRemoveProduct="handleRemoveProduct" @totalPriceUpdate="totalPriceUpdate" class="product-component" :product_data="product" quantity_editable="false" :total_price="totalPrice"></cart-product-component>
                                                            <div v-if="index < cartData.products.length - 1" class="py-2">
                                                                <v-divider></v-divider>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <!-- Continue Button -->
                                                <div class="pt-4">
                                                    <button class="ma-0 px-4 py-2 rounded body-heading font-weight-semi-bold light-blue-background white-color-text full-width-button text-transform-none-class" @click="step3Completed=true; step3Flag=false; step4Flag=true;scrollIntoView('step-4');">{{ $t("Customer.CartPage.Choose_a_Payment_Method")}}</button>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- Step 4: Payment -->
                                        <div id="step-4" class="mt-4 rounded-lg" :class="[step4Flag ? 'light-grey-border' : '']">
                                            <!-- Step 4 header -->
                                            <div class="px-4 py-2 d-flex justify-space-between align-center grey-background" :class="[step4Flag ? 'rounded-lg rounded-bl-0 rounded-br-0' : 'rounded-lg']" @click="step3Completed ? step4Flag=!step4Flag : step4Flag=false">
                                                <div class="d-flex">
                                                    <p class="ma-0 font-16px font-weight-semi-bold white-color-text step-number" :class="[step4Flag || step4Completed ? 'dark-blue-background' : 'dark-grey-background']">4</p>
                                                    <p class="ma-0 pl-2 body-heading font-weight-semi-bold" :class="[step4Flag || step4Completed ? 'dark-blue-color-text' : 'light-grey-color-text']" style="text-transform: uppercase;" align="left">{{ $t("Customer.CartPage.Payment")}}</p>
                                                </div>

                                                <img v-if="step4Completed" class="clickable-pointer" src="https://s3iconimages.mymedicine.com.mm/dropdown_blue_icon.svg" alt="dropdown-icon">
                                            </div>

                                            <!-- Step 4 Content -->
                                            <div class="pa-4" v-if="step4Flag">
                                                <div class="pb-2 d-flex justify-flex-start align-center clickable-pointer">
                                                    <input type="radio" id="kbzpay" :label="paymentMode" :value="paymentMode" v-model="selectedPaymentMode">
                                                    <img class="pl-2" src="https://s3iconimages.mymedicine.com.mm/KBZPay_icon.svg" alt="kbzpay-icon">
                                                    <p class="ma-0 pl-2 body-text font-weight-semi-bold">{{ $t("Customer.CartPage.KBZ_Pay")}}</p>
                                                </div>

                                                <!-- Continue Button -->
                                                <div class="pt-2 pay-now-web-segment">
                                                     <v-btn 
                                                    class="ma-0 px-4 py-2 rounded body-heading font-weight-semi-bold light-blue-background white-color-text full-width-button text-transform-none-class" color="#48ACEF"
                                                    :class="{ 'light-grey-background': !step4Flag || step4Completed, 'light-blue-background': step4Flag && !step4Completed }"
                                                    @click="startPay()"
                                                    :disabled="!step4Flag || step4Completed || isLoading"
                                                    elevation="0"
                                                    :loading="isLoading"
                                                    >
                                                    <span class="white-color-text">{{ $t("Customer.CartPage.Pay_Now")}}</span>
                                                </v-btn> 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Order Summary Segment -->
                            <div id="order-summary" v-if="!emptyCartFlag" class="order-summary-segment">
                                <div class="light-grey-dashed-border dashed-border-line"></div>
                                
                                <div class="ml-lg-2">
                                    <div class="pa-4 rounded-lg white-background">
                                        <!-- Heading -->
                                        <p class="ma-0 pb-4 body-heading font-weight-semi-bold" align="left">{{ $t("Customer.CartPage.Order_Details")}}</p>

                                        <!-- Billing Details -->
                                        <div class="mb-4 pa-4 rounded-lg light-grey-border">
                                            <p class="ma-0 body-text font-weight-semi-bold" align="left">{{ $t("Customer.CartPage.Billing_Details")}}</p>

                                            <div class="py-4">
                                                <v-divider></v-divider>
                                            </div>

                                            <div class="d-flex flex-column">
                                                <div class="pb-2 d-flex justify-space-between">
                                                    <p class="ma-0 body-text font-weight-regular">{{ $t("Customer.CartPage.Item_Total")}}</p>
                                                    <p class="ma-0 body-text font-weight-regular">{{ itemTotal }} MMK</p>
                                                </div>

                                                <div v-if="deliveryCharges != null" class="pb-2 d-flex justify-space-between">
                                                    <p class="ma-0 body-text font-weight-regular">{{ $t("Customer.CartPage.Delivery_Charges")}}</p>
                                                    <p class="ma-0 body-text font-weight-regular">{{ deliveryCharges }} MMK</p>
                                                </div>
                                            </div>

                                            <div class="py-4">
                                                <v-divider></v-divider>
                                            </div>

                                            <div class="d-flex justify-space-between">
                                                <p class="ma-0 body-heading font-weight-semi-bold light-blue-color-text">{{ $t("Customer.CartPage.Order_Total")}}</p>
                                                <p class="ma-0 body-heading font-weight-semi-bold light-blue-color-text">MMK {{ totalPrice }}</p>
                                            </div>
                                        </div>

                                        <!-- Shipping Address -->
                                        <div class="mb-4 pa-4 rounded-lg light-grey-border" v-if="selectedShippingAddressId && selectedShippingAddressId != 'new'">
                                            <!-- Heading -->
                                            <div class="d-flex justify-space-between">
                                                <p class="ma-0 body-text font-weight-semi-bold" align="left">{{ $t("Customer.CartPage.Shipping_Address")}}</p>
                                                <!-- <p class="ma-0 body-text font-weight-semi-bold light-blue-color-text" align="left">Change</p> -->
                                            </div>

                                            <!-- Address segment -->
                                            <div class="mt-4 py-2 rounded-lg grey-background">
                                                <div class="pa-4">
                                                    <p class="ma-0 body-text font-weight-medium dark-grey-color-text" align="left">Deliver to {{ shippingAddress.address_type_name }}</p>
                                                    <p class="ma-0 body-text font-weight-regular light-grey-color-text" align="left">{{ shippingAddress.delivered_to_name }},</p>
                                                    <p class="ma-0 body-text font-weight-regular light-grey-color-text" align="left">
                                                        {{ shippingAddress.house_number }}, {{ shippingAddress.street_address }}, {{ selectedShippingAddressState }}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <!-- Add a Note -->
                                        <!-- <div class="my-4 pa-4 rounded-lg light-grey-border"> -->
                                            <!-- Heading -->
                                            <!-- <p class="ma-0 pb-4 body-heading font-weight-semi-bold" align="left">Add a Note</p> -->

                                            <!-- Text Area -->
                                            <!-- <div class="pa-2 rounded-lg dark-grey-border">
                                                <v-textarea class="pa-0" placeholder="Write a note" color="black" auto-grow rows="1" row-height="60" v-model="description"></v-textarea>
                                            </div> -->
                                        <!-- </div> -->

                                        <!-- Cancellation Policy & FAQs -->
                                        <div class="redirection-tags">
                                            <div class="mt-4 d-flex justify-center align-center">
                                                <div class="d-flex align-center">
                                                    <img class="footer-icon" src="https://s3iconimages.mymedicine.com.mm/info_icon_light_blue.svg" alt="info-icon">
                                                    <p class="ma-0 pl-2 body-text font-weight-medium">{{ $t("Customer.CartPage.Cancellation_Policy")}}</p>
                                                </div>

                                                <div class="pl-4 d-flex align-center">
                                                    <img class="footer-icon" src="https://s3iconimages.mymedicine.com.mm/help_center_common_home_icon.svg" alt="">
                                                    <p class="ma-0 pl-2 body-text font-weight-medium">{{ $t("Customer.CartPage.FAQs")}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Footer -->
            <div class="footer-segment">
                <v-divider></v-divider>
            </div>
            <div class="pa-4 footer-segment">
                <common-home-footer></common-home-footer>
            </div>
            <!-- Bottom Sticky Bar -->
            <div v-if="selectedPaymentMode && totalPrice != null && !skeletonLoaderFlag && !emptyCartFlag" class="pay-now-mobile-segment bottom-navbar-segment">
                <v-bottom-navigation fixed app height="64" class="pa-4 navbar-container white-background">
                    <div class="full-width-button d-flex justify-space-between align-center">
                        <div class="d-flex flex-column">
                            <p class="ma-0 body-heading font-weight-bold">{{ totalPrice }} MMK</p>
                        </div>

                        <div class="half-width">
                                <v-btn 
                                class="ma-0 px-4 py-2 rounded full-width-button body-heading font-weight-semi-bold light-blue-background white-color-text text-transform-none-class" color="#48ACEF"
                                :class="{ 'light-grey-background': !step4Flag || step4Completed, 'light-blue-background': step4Flag && !step4Completed }"
                                @click="startPay()"
                                :disabled="!step4Flag || step4Completed || isLoading"
                                elevation="0"
                                :loading="isLoading"
                                >
                                <span class="white-color-text">{{ $t("Customer.CartPage.Pay_Now")}}</span>
                            </v-btn>  
                        </div>
                    </div>
                </v-bottom-navigation>
            </div>
        </v-app>
    </div>
</template>

<script>
import { axios_auth_instance_epharmacy_customer } from '../../../utils/axios_utils';
import axios from "axios";
export default {
    name: 'ePharmacyCartPageWithoutPrescriptionFlow',
    components: {
        'common-home-header': () => import('../../../components/navigationComponents/commonHomeHeader.vue'),
		'header-tabs-component': () => import('../../../components/navigationComponents/headerTabsComponent.vue'),
        'cart-product-component': () => import('../../../components/ePharmacyComponents/cartProductComponent.vue'),
        'bottom-navigation-bar': () => import('../../../components/navigationComponents/bottomNavigationBar.vue'),
        'common-home-footer': () => import('../../../components/navigationComponents/commonHomeFooter.vue')
    },
    data() {
        return {
            screenWidth: window.innerWidth,
            skeletonLoaderFlag: true,
            customerToken: null,
            isUnauthorizedFlag: false,
            isUserLoggedIn: false,
            step1Flag: true,
            step2Flag: false,
            step3Flag: false,
            step4Flag: false,
            step1Completed: false,
            step2Completed: false,
            step3Completed: false,
            step4Completed: false,
            step1FlagLoader: false,
            emptyCartFlag: false,
            isLoading:false,
            cartData: {
                products: [],
                addresses: []
            },
            addressStateList: null,
            addressCountryList: null,
            selectedShippingAddressId: null,
            description: '',
            fileUpload: false,
            fileUploadRules: [
                value => !!value || value.size > 100000 || 'File size too large. Maximum limit allowed 10mb.'
            ],
            uploadedFile: null,
            addAddressClicked: false,
            addAddressData: {
                country: 'Myanmar'
            },
            selectedShippingAddressType: null,
            shippingOtherAddressTypeName: null,
            totalPrice: null,
            itemTotal: null,
            deliveryCharges: null,
            quantityComponent: 0,
            editAddressPopupLoader: false,
            editAddressPopup: false,
            editAddressData: {},
            editAddressClickedFlag: false,
            shippingAddress: {},
            paymentMode: 'KBZ Pay',
            selectedPaymentMode: 'KBZ Pay',
            outOfStockProductsList: [],
            outOfStockFlag: false,
            outOfStockLoader: false,
            outOfStockWidth: 600,
            selectedShippingDeliveryOption: null,
            addressDeliverable: false,
            mobileNumberRules: [
                v => !!v || 'Mobile Number is required',
                v => (v && v.length >= 7 && v.length <= 11) || 'Mobile Number should be between 7 to 11 numbers',
                v => v > 0 || 'Mobile number cannot be negative',
                v => this.checkZero(v) || 'Mobile number should start with 0'
            ],
            mobileNumberRulesMM: [
                v => !!v || 'ဖုန်းနံပါတ်ထည့်သွင်းရန် လိုအပ်ပါသည်။',
                v => (v && v.length >= 7 && v.length <= 11) || 'ဖုန်းနံပါတ်၏ ဂဏန်းအရေအတွက်မှာ ၇ လုံးမှ ၁၁ လုံးဖြစ်ရပါမည်။',
                v => v > 0 || 'Mobile number cannot be negative',
                v => this.checkZero(v) || 'Mobile number should start with 0'
            ],
            addAddressStepLoader: false,
            somethingWentWrong: false,
            callpermissionflag: false,
            selectedShippingAddressState: '',
            deliveryOptionsData: [],
            error_enable: false,
            auth_error_enable: false
        };
    },
    watch: {
		screenWidth(newWidth, oldWidth) {
            if (newWidth <= 600) {
                this.outOfStockWidth = 340;
            } else if (newWidth > 601 && newWidth <= 1264) {
                this.outOfStockWidth = 800;
            } else {
                this.outOfStockWidth = 800;
            }
		},
        selectedShippingAddressId(newSelectedShippingAddressId) {
            this.selectedShippingDeliveryOption = null;
            if(newSelectedShippingAddressId != 'Other' && newSelectedShippingAddressId != 'new') {
                let selectedShippingAddressIndex = this.cartData.addresses.findIndex((address) => address.address_unique_id === newSelectedShippingAddressId);
                this.shippingAddress = this.cartData.addresses[selectedShippingAddressIndex];
                this.selectedShippingAddressState = (this.deliveryOptionsData.find(addressState => addressState.state_id === this.shippingAddress.state_id)).state_name;
            }

            if (newSelectedShippingAddressId == 'new') {
                this.addressDeliverable = false;
                // Fetch state & country codes from BE.
                axios_auth_instance_epharmacy_customer.get('/address/stateCountryList').then((getStateCountryIdListResponse) =>{
                    this.addressStateList = getStateCountryIdListResponse.data.data.listOfState;
                    this.addressCountryList = getStateCountryIdListResponse.data.data.listOfCountry;
                }).catch((getStateCountryIdListError) => {
                    console.log('Error fetch state & country list: ', getStateCountryIdListError);
                    if (getProductDetailsError.response && getProductDetailsError.response.status == 401) {
                        this.$cookies.remove('customerToken');
                        this.auth_error_enable = true;
                    } else {
                        this.error_enable = true;
                    }
                });


            }
        },

	},
    mounted() {
        window.addEventListener('resize', this.handleResize);
        
        // If user is logged out, pushing user to TelemedLogin
        this.currentCustomer = this.$cookies.get("customerToken");
        if(!this.currentCustomer) {
            if (!window.xm) {
                this.$router.push({
                    name: 'CustomerHomeScreen'
                });
            } else {
                this.$router.push({
                    name: 'CommonHomePage'
                });
            }
        } else {
            // Call viewParticularCart API.
            axios_auth_instance_epharmacy_customer.get('/cart').then((viewParticularCartResponse) => {
                // Login Check.
                this.isUserLoggedIn = true;

                this.cartDataResponse = viewParticularCartResponse.data.data[0];

                this.cartData.products = this.cartDataResponse.orderDetails ? this.cartDataResponse.orderDetails.filter((product) => !product.is_delivery_object) : [];
                this.itemTotal = (this.cartData.products && this.cartData.products.length > 0) ? this.cartDataResponse.amount_total : null;
                if (this.itemTotal) {
                    const deliveryItem = this.cartDataResponse.orderDetails.find(item => item.is_delivery_object);
                    if(deliveryItem)
                        this.itemTotal -= deliveryItem.price_unit;
                }
                this.totalPrice = this.itemTotal;
                
                // Fetch Addresses.
                this.cartData.addresses = viewParticularCartResponse.data.delivery_address ? viewParticularCartResponse.data.delivery_address : [];
                this.cartData.addresses.push({
                    address_unique_id: 'new',
                    address_type_name: 'new',
                    add_address: true
                });

                this.emptyCartFlag = !(this.cartData.products && this.cartData.products.length > 0);
                
                this.shippingAddress = this.cartData.addresses[0];

                // Check stock against each product.
                // every((product) => product.stock_qty >= product.cart_qty);
                let stockFlag = this.cartData.products.forEach((product) => {
                    if (!product.is_delivery_object && product.stock_qty < product.cart_qty) {
                        this.outOfStockProductsList.push(product);
                    }
                });

                if (this.outOfStockProductsList.length > 0) {
                    this.outOfStockFlag = true;
                }

                let addressIdsList = this.cartData.addresses.map((address) => Object({address_id: address.address_unique_id, state_id: address.state_id}));
                addressIdsList.pop();
                this.skeletonLoaderFlag = false;

                // API Call.
                this.step1FlagLoader = true;
                if (addressIdsList.length > 0) {
                    axios_auth_instance_epharmacy_customer.get('/cart/getDeliveryCharges', { params: { deliveryStateList: JSON.stringify(addressIdsList) } }).then((getDeliveryChargesResponse) => {
                        this.deliveryOptionsData = getDeliveryChargesResponse.data.data.data;
                        this.selectedShippingAddressId = this.cartData.addresses[0].address_unique_id;
                        this.selectedShippingAddressState = (this.deliveryOptionsData.find(addressState => addressState.state_id === this.shippingAddress.state_id)).state_name;

                        this.cartData.addresses.forEach((address, index) => {
                            if (address.address_unique_id != 'new') {
                                let deliveryOptionsIndex = this.deliveryOptionsData.findIndex((deliveryOptionsRecord) => deliveryOptionsRecord.address_id == address.address_unique_id);

                                this.cartData.addresses[index].is_deliverable = this.deliveryOptionsData[deliveryOptionsIndex].is_deliverable;
                                this.cartData.addresses[index].delivery_details = this.deliveryOptionsData[deliveryOptionsIndex].delivery_details;
                                this.cartData.addresses[index].state_name = this.deliveryOptionsData[deliveryOptionsIndex].state_name
                            }
                        });

                        this.checkIfAddressIsDeliverable(this.cartData.addresses[0].is_deliverable);
                        this.step1FlagLoader = false;
                        this.callpermissionflag = true;
                    }).catch((getDeliveryChargesError) => {
                        console.log('Error in fetching delivery options: ', getDeliveryChargesError);
                        if (getDeliveryChargesError.response && getDeliveryChargesError.response.status == 401) {
                            this.auth_error_enable = true;
                        } else {
                            this.error_enable = true;
                        }
                    });
                } else {
                    this.step1FlagLoader = false;
                }
            }).catch((viewParticularCartError) => {
                console.log('Error fetching cart details: ', viewParticularCartError);

                if (viewParticularCartError.response.status == 401) {
                    this.isUserLoggedIn = false;
                    this.isUnauthorizedFlag = true;
                    this.$cookies.remove('customerToken');
                    this.auth_error_enable = true;
                } else if (viewParticularCartError.response.status == 404) {
                    this.skeletonLoaderFlag = false;
                    this.emptyCartFlag = true;
                } else {
                    this.error_enable = true;
                }

                // Throw error popup.
            });
            this.userPageViewTable();
        }
    },
    beforeUnmount() {
		window.removeEventListener('resize', this.handleResize);
	},
    methods: {
        userPageViewTable() {
            if (this.currentCustomer === null) {
                var userPageViewTableBody = {
                    pageName : 'cartPage',
                    typeOfUser: 'CUSTOMER'
                }
            } else {
                var userPageViewTableBody = {
                    pageName: 'cartPage',
                    typeOfUser: 'CUSTOMER',
                    token: this.currentCustomer
                }
            }
            axios.post(process.env.VUE_APP_BACKEND_URL+"/userPageViewTable", userPageViewTableBody)
            .catch((userPageViewTableError) => {
                console.log('[Error][userPageTableError] Error in adding userPageViewTable', userPageViewTableError);
            });
        },
        updatingCart() {
            this.isLoading = true;          
        },
        goBackFromCartPage() {
            window.history.back();
        },
        checkZero(number) {
            return !number || number.toString()[0] == "0";
        },
        checkIfAddressIsDeliverable(deliverFlag) {
            this.addressDeliverable = deliverFlag;
        },
        handleResize() {
			this.screenWidth = window.innerWidth;
			if (this.screenWidth <= 600) {
				
			} else {
				
			}
		},
        scrollIntoView(id) {
            let element = document.getElementById(id);
            if (id == 'delivery-options' && this.selectedShippingAddressId == 'new') {
                // No Scroll
            } else {
                element.scrollIntoView(true);
            }
            
            
            if (id == 'order-summary') {
                element.click();
            }
        },
        handleRemoveProduct(productId) {
            let productIndex = this.cartData.products.findIndex((product) => productId == product.product_id);
            this.totalPrice -= this.cartData.products[productIndex].cart_qty * this.cartData.products[productIndex].price_unit;
            this.itemTotal -= this.cartData.products[productIndex].cart_qty * this.cartData.products[productIndex].price_unit;
            this.cartData.products.splice(productIndex, 1);

            if (this.cartData.products.length == 0) {
                this.emptyCartFlag = true;
            }
            
            this.isLoading = false;
        },
        addAddressToCart() {
            this.addAddressStepLoader = true;

            let addressIndex = this.cartData.addresses.findIndex((address) => address.address_unique_id == this.selectedShippingAddressId);

            let deliveryOptionIndex = this.cartData.addresses[addressIndex].delivery_details.findIndex((deliveryOption) => deliveryOption.delivery_object_id == this.selectedShippingDeliveryOption);
            
            // addAddressToCart API call.
            let addExistingAddressToCartRequest = {
                address_unique_id: String(this.cartData.addresses[addressIndex].address_unique_id),
                delivery_product_id: String(this.cartData.addresses[addressIndex].delivery_details[deliveryOptionIndex].delivery_object_id)
            };

            axios_auth_instance_epharmacy_customer.put('/cart/addAddress', addExistingAddressToCartRequest).then((addExistingAddressToCartResponse) => {
                this.deliveryCharges = this.cartData.addresses[addressIndex].delivery_details[deliveryOptionIndex].delivery_price;
                this.totalPrice += this.deliveryCharges;

                this.step2Completed=true;
                this.step2Flag=false;
                this.step3Flag=true;
                this.scrollIntoView('step-3');
                this.addAddressStepLoader = false;
            }).catch((addExistingAddressToCartError) => {
                console.log('Error in addExistingAddressToCart API: ', addExistingAddressToCartError);

                // On Error, reset values.
                if (addExistingAddressToCartError.response && addExistingAddressToCartError.response.status == 401) {
                    this.$cookies.remove('customerToken');
                    this.auth_error_enable = true;
                } else {
                    this.error_enable = true;
                }
            });
        },
        resetAddressStep() {
            this.selectedShippingAddressId = this.cartData.addresses[0].address_unique_id;
            this.shippingAddress = this.cartData.addresses[0];
            this.selectedShippingDeliveryOption = null;

            this.addAddressStepLoader = false;
            this.somethingWentWrong = false;
        },
        addAddress() {
            this.addAddressClicked = true;

            let addressTypeName;
            if (this.selectedShippingAddressType === 'Other') {
                addressTypeName = this.shippingOtherAddressTypeName;
            } else {
                addressTypeName = this.selectedShippingAddressType;
            }

            // Add address API call.
            let createAddressRequest = {
                deliverTo: this.addAddressData.delivered_to_name,
                addressLine1: this.addAddressData.house_number,
                addressLine2: this.addAddressData.street_address,
                addressTypeName: addressTypeName,
                stateId: this.addAddressData.state.id,
                mobile: this.addAddressData.phone_number,
                landmark: this.addAddressData.landmark
            };

            axios_auth_instance_epharmacy_customer.post('/address', createAddressRequest)
            .then((createAddressResponse) => {
                this.cartData.addresses.splice(this.cartData.addresses.length - 1, 0, {
                    address_unique_id: createAddressResponse.data.address_unique_id,
                    address_type_name: addressTypeName,

                    delivered_to_name: this.addAddressData.delivered_to_name,
                    mobile_number: this.addAddressData.phone_number,
                    street_address: this.addAddressData.street_address,
                    house_number: this.addAddressData.house_number,
                    landmark: this.addAddressData.landmark,
                    state_id: this.addAddressData.state.id,

                    is_deliverable: createAddressResponse.data.is_deliverable,
                    delivery_details: (createAddressResponse.data.is_deliverable ? (createAddressResponse.data.delivery_details.map((deliveryObject) => Object({
                        delivery_name: deliveryObject.name,
                        delivery_object_id: deliveryObject.id,
                        delivery_price: deliveryObject.list_price
                    }))) : null)
                });

                this.selectedShippingAddressId = createAddressResponse.data.address_unique_id;
                this.shippingAddress = this.cartData.addresses[this.cartData.addresses.length - 2];

                this.addAddressClicked = false;
                this.addAddressData.delivered_to_name = '';
                this.addAddressData.house_number = '';
                this.addAddressData.street_address = '';
                this.addAddressData.state = '';
                this.selectedShippingAddressType = null;
                this.shippingOtherAddressTypeName = null;
                this.addAddressData.phone_number = '';
                this.addAddressData.landmark = '';
                this.addressDeliverable = createAddressResponse.data.is_deliverable;
            }).catch((createAddressError) => {
                console.log('Error adding address: ', createAddressError);
                if (createAddressError.response && createAddressError.response.status == 401) {
                    this.$cookies.remove('customerToken');
                    this.auth_error_enable = true;
                } else {
                    this.error_enable = true;
                }
            });
        },
        editAddressPopupOpen(editAddressIndex) {
            this.editAddressData = Object.assign({}, this.cartData.addresses[editAddressIndex]);

            // Fetch State Name.
            if (this.addressStateList == null || this.addressStateList == []) {
                this.editAddressPopupLoader = true;
                axios_auth_instance_epharmacy_customer.get('/address/stateCountryList').then((getStateCountryIdListResponse) =>{
                    this.addressStateList = getStateCountryIdListResponse.data.data.listOfState;
                    this.addressCountryList = getStateCountryIdListResponse.data.data.listOfCountry;

                    let stateIndex = this.addressStateList.findIndex((state) => String(state.id) == String(this.editAddressData.state_id));
                    this.editAddressData.state = this.addressStateList[stateIndex];
                    this.editAddressPopupLoader = false;
                    this.editAddressPopup = true;
                }).catch((getStateCountryIdListError) => {
                    console.log('Error fetch state & country list: ', getStateCountryIdListError);
                    if (getStateCountryIdListError.response && getStateCountryIdListError.response.status == 401 ) {
                        this.$cookies.remove('customerToken');
                        this.auth_error_enable = true;
                    } else {
                        this.error_enable = true;
                    }
                });
            } else {
                let stateIndex = this.addressStateList.findIndex((state) => String(state.id) == String(this.editAddressData.state_id));
                this.editAddressData.state = this.addressStateList[stateIndex];
                this.editAddressPopup = true;
            }
        },
        editAddressPopupClose() {
            this.editAddressPopup = false;
            this.editAddressData = {};
        },
        editAddress() {
            this.editAddressClickedFlag = true;

            let updateAddressRequest = {
                deliverTo: this.editAddressData.delivered_to_name,
                addressLine1: this.editAddressData.house_number,
                addressLine2: this.editAddressData.street_address,
                addressTypeName: this.editAddressData.address_type_name,
                stateId: this.editAddressData.state.id,
                mobile: this.editAddressData.mobile_number,
                landmark: this.editAddressData.landmark
            };

            axios_auth_instance_epharmacy_customer.put('/address/' + this.editAddressData.address_unique_id, updateAddressRequest).then((updateAddressResponse) =>{
                let editAddressIndex = this.cartData.addresses.findIndex((address) => String(address.address_unique_id) == String(this.editAddressData.address_unique_id));
                this.cartData.addresses[editAddressIndex] = {
                    delivered_to_name: this.editAddressData.delivered_to_name,
                    street_address: this.editAddressData.street_address,
                    city: this.editAddressData.city,
                    address_unique_id: this.editAddressData.address_unique_id,
                    address_type_name: this.editAddressData.address_type_name,
                    house_number: this.editAddressData.house_number,
                    state_id: this.editAddressData.state.id,
                    mobile_number: this.editAddressData.mobile_number,
                    landmark: this.editAddressData.landmark,
                    is_deliverable: updateAddressResponse.data.address_data.is_deliverable,
                    delivery_details: updateAddressResponse.data.address_data.is_deliverable ? (updateAddressResponse.data.address_data.delivery_details.map((deliveryObject) => Object({
                        delivery_name: deliveryObject.name,
                        delivery_object_id: deliveryObject.id,
                        delivery_price: deliveryObject.list_price
                    }))) : null
                };

                if (this.editAddressData.address_unique_id == this.selectedShippingAddressId) {
                    this.addressDeliverable = updateAddressResponse.data.address_data.is_deliverable;
                    this.shippingAddress = this.cartData.addresses[editAddressIndex];
                }

                this.editAddressClickedFlag = false;
                this.editAddressPopup = false;
            }).catch((updateAddressError) => {
                console.log('Error editing address: ', updateAddressError);
                this.editAddressPopup = false;
                if (updateAddressError.response && updateAddressError.response.status == 401) {
                    this.$cookies.remove('customerToken');
                    this.auth_error_enable = true;
                } else {
                    this.error_enable = true;
                }
            });
        },
        startPay() {
            this.isLoading=true;
            let initiatePaymentRequest = {
                isMobile: this.screenWidth <= 600 ? true : false
            };

            axios_auth_instance_epharmacy_customer.post('/cart/payment', initiatePaymentRequest).then((initiatePaymentResponse) => {
                console.log('initiatePaymentResponse: ', initiatePaymentResponse);

                if (this.screenWidth <= 600) {
                    let pwa_signed_url = initiatePaymentResponse.data.payment_data.PWA_signed_url;
                    window.location.replace(pwa_signed_url);
                } else {
                    this.$router.push({
                        name: "QRCode",
                        params: {
                            booking_id: initiatePaymentResponse.data.booking_id,
                            qr_code: initiatePaymentResponse.data.payment_data.qr_code,
                            amount: initiatePaymentResponse.data.amount
                        },
                    });
                }
                if (window.xm){
                    document.title = '';
                } else {
                    document.title = 'Order Placed';
                }
            }).catch((initiatePaymentError) => {
                console.log('initiatePaymentError: ', initiatePaymentError);
                if (initiatePaymentError.response && initiatePaymentError.response.status == 401) {
                    this.$cookies.remove('customerToken');
                    this.auth_error_enable = true;
                } else {
                    this.error_enable = true;
                }
            });
        },
        unauthorizedPopupClose() {
            this.isUnauthorizedFlag = false;

            if (!window.xm) {
                this.$router.push({
                    name: 'CustomerHomeScreen'
                });
            } else {
                this.$router.push({
                    name: 'CommonHomePage'
                });
            }
        },
        outOfStockPopupClose() {
            this.outOfStockLoader = true;
            // Call addToCart/removeFromCart API.
            let updateCartOnStockUpdateRequest = {
                cartProductList: this.outOfStockProductsList.map((product) => product.stock_qty == 0 ? Object({productId: product.product_id, productQty: 0}) : Object({productId: product.product_id, productQty: product.cart_qty - product.stock_qty}))
            };

            axios_auth_instance_epharmacy_customer.post('/cart/updateCart', updateCartOnStockUpdateRequest).then((updateCartOnStockUpdateResponse) => {
                // Reduce size.
                this.outOfStockProductsList.forEach((outOfStockProduct) => {
                    let productIndex = this.cartData.products.findIndex((product) => product.product_id == outOfStockProduct.product_id);

                    if (outOfStockProduct.stock_qty == 0) {
                        this.cartData.products.splice(productIndex, 1);
                    } else if (outOfStockProduct.cart_qty - outOfStockProduct.stock_qty > 0) {
                        this.cartData.products[productIndex] = {
                            cart_qty: outOfStockProduct.stock_qty,
                            ...this.cartData.products[productIndex]
                        };
                    }
                });

                this.outOfStockLoader = false;
            }).catch((updateCartOnStockUpdateError) => {
                console.log('updateCartOnStockUpdateError: ', updateCartOnStockUpdateError);
                if (updateCartOnStockUpdateError.response && updateCartOnStockUpdateError.response.status == 401) {
                    this.$cookies.remove('customerToken');
                    this.auth_error_enable = true;
                } else {
                    this.error_enable = true;
                }
            });
            this.outOfStockFlag = false;
        },
        totalPriceUpdate() {
            let newTotalPrice = 0;
            this.cartData.products.forEach((product) => {
                if (product.price_unit === product.price_reduce) {
                    newTotalPrice += product.price_unit * product.cart_qty;
                } else {
                     newTotalPrice += product.price_reduce * product.cart_qty;
                }
            });
            this.itemTotal = newTotalPrice;
            this.totalPrice = newTotalPrice;
            if (this.deliveryCharges && this.deliveryCharges > 0) {
                this.totalPrice += this.deliveryCharges;
            }
            this.isLoading = false;
        },
        redirectTo(pageName, params) {
            this.$router.push({
                name: pageName
            });
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../../scss/global.scss';
@import '../../../scss/classes.scss';


.adjust_pop{
    padding-left: 16px !important;
    padding-right: 16px !important;
    // max-height: 800px !important;
}
.adjust_header{
    padding: 16px 2px 10px !important;
}
.disabledButton {
    width: 200px;
    color: #828282;
    background-color: #F7F8FA;
}
.editAddressButton {
    min-width: 130px !important;
    color: white;
    background-color: #48ACEF !important;
    margin-right: 0px !important;
}
.cancel_Button{
    min-width: 130px !important;
    color: white;
    background-color: #EB5757 !important;
}
.small-button{
    font-size: 12px;
    padding: 5px 10px !important;
}
.custom-dialog-content{
    max-height: 1000px !important;
   
}
.reduce_top{
    margin-top: -10px;
    margin-bottom: 10px !important;
   
}
.AddAddressButton {
    width: 200px;
    color: white !important;
    background-color: #48ACEF !important;
}
.empty-cart-actions-web, .navigation-tabs-segment, .cart-heading, .footer-segment, .shipping-address-web-component, .redirection-tags, .pay-now-web-segment {
    @media (max-width: 600px) {
        display: none;
	}
}
.empty-cart-actions-mobile, .mobile-header-segment, .shipping-address-mobile-component, .shipping-address-mobile-component, .pay-now-mobile-segment {
    @media (min-width: 601px) {
		display: none;
	}
}

.loader-web {
    @media (max-width: 1264px) {
        display: none;
	}
}

.loader-mobile {
    @media (min-width: 1265px) {
		display: none;
	}
}

.empty-cart-segment {
    @media (max-width: 600px) {
        height: 70vh;
	}

	@media (min-width: 601px) and (max-width: 1264px) {
		height: 70vh;
	}

	@media (min-width: 1265px) {

    }
}

.empty-cart-web-width {
    @media (min-width: 1265px) {
		max-width: 480px;
	}
}

.empty-cart-action-button {
    width: 200px;
}

.step-number {
    // line-height: 0px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
}

.px-icon {
    width: 20px;
    height: 20px;
}

.main-segment-background {
    height: 100%;

    @media (max-width: 600px) {
        // padding: 16px;
	}

	@media (min-width: 601px) and (max-width: 1264px) {
		// padding: 16px;
	}

	@media (min-width: 1265px) {
		background-color: $light-grey-color;
        padding: 24px;
	}
}

.order-flow-segment {
    @media (max-width: 600px) {
        width: 100%;
	}

	@media (min-width: 601px) and (max-width: 1264px) {
		width: 100%;
	}

	@media (min-width: 1265px) {
		width: 60%;
        border-radius: 8px;
	}
}

.order-summary-segment {
    @media (max-width: 600px) {
        width: 100%;
	}

	@media (min-width: 601px) and (max-width: 1264px) {
		width: 100%;
	}

	@media (min-width: 1265px) {
		width: 40%;
	}
}

.from-device-segment, .from-previous-consultation-segment {
    width: 48%;

    .button-class {
        width: 100%;
        letter-spacing: 0px;
    }
}

.upload-options-layout {
    h3 {
        height: 10vh;
    }

    @media (max-width: 600px) {
        flex-direction: column;
        align-items: flex-start;        

        h3, .upload-options-button {
            width: 100%;
            padding-top: 8px;
        }
	}

	@media (min-width: 601px) and (max-width: 1264px) {
        align-items: center;

		h3, .upload-options-button {
            padding-left: 8px;
        }
	}

	@media (min-width: 1265px) {
        align-items: center;

		h3, .upload-options-button {
            padding-left: 8px;
        }
	}
}

.address-list-section {
    max-height: 600px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.full-width-button, .address-radio-item {
    width: 100%;
}

.shipping-radio-item {
    width: 50%;
}

.footer-icon {
    width: 20px;
    height: 20px;
}

.dashed-border-line {
    @media (min-width: 1265px) {
		display: none;
	}
}
.half-width {
    width: 50%;
}

.one-third-width {
    width: 33%;
}

.thumbnail-close-icon {
    @media (max-width: 600px) {
        width: 24px;
        height: 24px;
    }

    @media (min-width: 601px) and (max-width: 1264px) {
        width: 24px;
        height: 24px;
    }

    @media (min-width: 1265px) {
        width: 24px;
        height: 24px;
    }
}

.mobile-bottom-nav-bar {
	@media (max-width: 600px) {
        
	}

	@media (min-width: 601px) and (max-width: 1264px) {
		display: none !important;
	}

	@media (min-width: 1265px) {
		display: none !important;
	}
}
.edit-address-action-button {
    min-width: 200px;
}

.cart-updated-icon {
    @media (max-width: 600px) {
        width: 64px;
        height: 64px;
	}

	@media (min-width: 601px) and (max-width: 1264px) {
        width: 80px;
        height: 80px;
    }

	@media (min-width: 1265px) {
        width: 80px;
        height: 80px;
    }
}

.navbar-container {
    margin: 0px;
    box-shadow: 0px -4px 24px rgba(0, 36, 74, 0.1);
}
.light-grey-background {
  background-color: #ccc; 
  color: #fff; 
}
.light-blue-background {
  background-color: #48ACEF; 
  color: #fff; 
}
.disabled-button { 
    background-color: #f7f8fa; 
    color: #828282; 
    } 
 .active-button { 
        background-color: #48acef; 
        color: white; 
    }
</style>

<style lang="scss">
@import '../../../scss/global.scss';
@import '../../../scss/classes.scss';

.v-input__slot::before, .v-input__slot::after {
    display: none;
    margin-bottom: 0px !important;
}

.state-segment.v-text-field.v-text-field--enclosed .v-text-field__details {
    display: none;
}
.state-segment .v-input__slot {
    margin-bottom: 0px !important;
}

.state-segment input {
    @media (max-width: 600px) {
        font-size: 12px !important;
	}
}

.navbar-container .v-item-group.v-bottom-navigation {
    box-shadow: unset !important;
}
</style>